<template>
  <v-container fluid>
    <!-- <v-btn color="warning" @click="generarReportePapu">Ver Reporte</v-btn> -->
    <v-data-table
      :headers="headers"
      :items="documentData"
      item-key="documentNumber"
      sort-by="Employed"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingClients"
      loading-text="Cargando Recibos..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por empleado / cliente / Nro. / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado') && $route.name !== 'clientsassigned'"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="ReportDocument(item.documentNumber)"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
          </template>
          <span>Re-Imprimir Recibo</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  mdiPrinter,
} from '@mdi/js'
import axios from 'axios'

export default {
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },

  },
  data: () => ({
    dialogDelete: false,
    icons: {
      mdiPrinter,

    },
    headers: [
      {
        text: 'Nro.',
        align: 'start',
        sortable: true,
        value: 'documentNumber',
      },
      { text: 'Empleado', value: 'employed' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Cliente', value: 'client' },
      { text: 'Monto', value: 'amount' },
      { text: 'Saldo', value: 'amountLeftToPay' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    documentData: [],
    buscar: '',
    loadingClients: false,
    mensajeSnack: '',

  }),

  mounted() {
    this.getDocumentPayment()
  },
  methods: {

    ReportDocument(item) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'reportpaymentdocument', params: { documentnumber: item, ruta: 'Recibos' } })
    },
    async getDocumentPayment() {
      this.url = `${this.$store.getters.urlBase}/api/Report/DocumentPaymentList`

      // const employeeId = this.$route.params.id ? this.$route.params.id : parseInt(this.$store.getters.usuario.user_personid)
      let employeeId = 0
      if ((this.rol === 'Administrador' || this.rol === 'Gerente' || this.rol === 'Encargado')) {
        employeeId = null
      } else {
        employeeId = this.$store.getters.usuario.user_personid
      }
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idPerson: employeeId },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.documentData = respuesta.data
        this.loadingClients = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },
  },
}
</script>
