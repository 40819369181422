<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Listado de Recibos</v-card-title>
        <DataTableDocumentPayment
          :rol="rolUserLogged"
          :state-client="this.$route.params.clientState"
        ></DataTableDocumentPayment>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableDocumentPayment from './datatables/DataTableDocumentPayment.vue'

export default {
  components: {

    DataTableDocumentPayment,

  },
  data: () => ({
    rolUserLogged: null,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },

}
</script>
